<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form label-position="left">
        <el-row :gutter="40">
          <el-col :span="12">
            <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">页面参数</div>
            <el-form-item label="用户端名称">
              <el-input v-model="form.front_name"></el-input>
            </el-form-item>
            <el-form-item label="购买协议">
              <br>
              <y_editor v-model="form.agreement"></y_editor>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">规则配置</div>
            <el-form-item label="仅免单会员可购买">
              <el-switch v-model="form.only_card_member"></el-switch>
            </el-form-item>
            <el-divider content-position="left">普通主理人</el-divider>
            <el-form-item label="用户端名称">
              <el-input v-model="form.normal_front_name"></el-input>
            </el-form-item>
            <el-form-item label="原价">
              <el-input-number v-model="form.normal_market_price"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="售价">
              <el-input-number v-model="form.normal_price"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="激活卡">
              <el-input-number v-model="form.normal_card"></el-input-number>
              个
            </el-form-item>
            <el-form-item label="赠送免单会员">
              <el-input-number v-model="form.normal_give_card_rights"></el-input-number>
              天
            </el-form-item>
            <el-form-item label="赠送积分">
              <el-input-number v-model="form.normal_give_credit"></el-input-number>
            </el-form-item>
            <el-form-item label="直推前两主理人返佣">
              <el-input-number v-model="form.normal_first_or_seconds_award"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="直推非第一、二个奖励">
              <el-input-number v-model="form.normal_direct_not_fs"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="其它团队奖励">
              <el-input-number v-model="form.normal_root_award"></el-input-number>
              元
            </el-form-item>
            <el-divider content-position="left">金牌主理人</el-divider>
            <el-form-item label="用户端名称">
              <el-input v-model="form.pro_front_name"></el-input>
            </el-form-item>
            <el-form-item label="原价">
              <el-input-number v-model="form.pro_market_price"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="售价">
              <el-input-number v-model="form.pro_price"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="激活卡">
              <el-input-number v-model="form.pro_card"></el-input-number>
              个
            </el-form-item>
            <el-form-item label="赠送免单会员">
              <el-input-number v-model="form.pro_give_card_rights"></el-input-number>
              天
            </el-form-item>
            <el-form-item label="赠送积分">
              <el-input-number v-model="form.pro_give_credit"></el-input-number>
            </el-form-item>
            <el-form-item label="直推前两主理人返佣">
              <el-input-number v-model="form.pro_first_or_seconds_award"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="直推非第一、二个奖励">
              <el-input-number v-model="form.pro_direct_not_fs"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="其它团队奖励">
              <el-input-number v-model="form.pro_root_award"></el-input-number>
              元
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import y_editor from "@/components/y-form/y-editor/y_editor";

export default {
  name: "edit",
  components: {
    y_editor,
  },
  data() {
    return {
      form: {
        front_name: "",
        only_card_member: false,
        normal_front_name: "",
        normal_market_price: 0,
        normal_price: 0,
        normal_card: 0,
        normal_give_card_rights: 0,
        normal_give_credit: 0,
        normal_first_or_seconds_award: 0,
        normal_direct_not_fs: 0,
        normal_root_award: 0,

        pro_front_name: "",
        pro_market_price: 0,
        pro_price: 0,
        pro_card: 0,
        pro_give_card_rights: 0,
        pro_give_credit: 0,
        pro_direct_not_fs: 0,
        pro_first_or_seconds_award: 0,
        pro_root_award: 0,

        agreement: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.card.plugin.principal.rule().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.card.plugin.principal.ruleEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>